import { Suspense } from "react";
import "./index.css"; // 导入Tailwind CSS
// import { Img } from "react-image";
import avatar from "./static/images-school/avatar.jpg";
import vercel from "./static/images-school/vercel.jpg";
import { Image } from "antd";
import { Divider, Typography } from "antd";
import nin_x from "./static/nin_x.jpg";
import ryudaiAndNin1 from "./static/images-school/home/ryudaiAndNin1.png";
import ryu4 from "./static/ryu4.jpg";
import ryu1 from "./static/ryu1.jpg";
import ryu6 from "./static/ryu6.jpg";
import nin1 from "./static/images-school/home/nin1.jpg";
import nin2 from "./static/nin2.jpg";
import qr1 from "./static/qr1.jpg";

import logo_td from "./static/images-school/logo/logo_td.png";
import logo_waseda from "./static/logo_waseda.png";
import logo_shiba from "./static/logo_shiba.png";
import logo_sofia from "./static/logo_sofia.jpg";
import logo_touyou from "./static/logo_touyou.jpg";

import toudai1 from "./static/toudai1.jpg";

const { Title, Paragraph, Text, Link } = Typography;

function Badge(props) {
  return (
    <a
      {...props}
      target="_blank"
      className="inline-flex items-center rounded border border-neutral-200 bg-neutral-50 p-1 text-sm leading-4 text-neutral-900 no-underline dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-100"
    />
  );
}

function BlogLink({ slug, name }) {
  return (
    <div className="group">
      <a
        href={`/blog/${slug}`}
        className="flex w-full items-center justify-between rounded border border-neutral-200 bg-neutral-50 px-3 py-4 dark:border-neutral-700 dark:bg-neutral-800"
      >
        <div className="flex flex-col">
          <p className="font-medium text-neutral-900 dark:text-neutral-100">
            {name}
          </p>
        </div>
        {/* <div className="transform text-neutral-700 transition-transform duration-300 group-hover:-rotate-12 dark:text-neutral-300">
          <ArrowIcon />
        </div> */}
      </a>
    </div>
  );
}

function BlogLinkBig({ name, img }) {
  return (
    <div className="group flex w-full">
      <a
        // href={link}
        target="_blank"
        className="flex w-full items-center justify-between rounded border border-neutral-200 bg-neutral-50 px-3 py-4 dark:border-neutral-700 dark:bg-neutral-800"
      >
        <div className="flex items-center space-x-3">
          <div className="relative h-8">
            <Image
              alt={name}
              src={img}
              height={30}
              width={30}
              sizes="33vw"
              className="h-8 w-16 rounded-full "
              priority
            />
          </div>
          <div className="flex flex-col">
            <p className="font-medium text-neutral-900 dark:text-neutral-100">
              {name}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
}

function ChannelLink({ img, link, name }) {
  return (
    <div className="group flex w-full">
      <a
        href={link}
        target="_blank"
        className="flex w-full items-center justify-between rounded border border-neutral-200 bg-neutral-50 px-3 py-4 dark:border-neutral-700 dark:bg-neutral-800"
      >
        <div className="flex items-center space-x-3">
          <div className="relative h-16">
            <Image
              alt={name}
              src={img}
              height={64}
              width={64}
              sizes="33vw"
              className="h-16 w-16 rounded-full border border-neutral-200 dark:border-neutral-700"
              priority
            />
          </div>
          <div className="flex flex-col">
            <p className="font-medium text-neutral-900 dark:text-neutral-100">
              {name}
            </p>
          </div>
        </div>
        {link !== "" && (
          <div className="transform text-neutral-700 transition-transform duration-300 group-hover:-rotate-12 dark:text-neutral-300">
            <ArrowIcon />
          </div>
        )}
      </a>
    </div>
  );
}

function ArrowIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.07102 11.3494L0.963068 10.2415L9.2017 1.98864H2.83807L2.85227 0.454545H11.8438V9.46023H10.2955L10.3097 3.09659L2.07102 11.3494Z"
        fill="currentColor"
      />
    </svg>
  );
}

// const Image = ({
//   src,
//   alt,
//   width,
//   height,
//   layout = "intrinsic", // Default layout
//   objectFit = "cover",
//   ...props
// }) => {
//   const styles = {
//     width: layout === "fill" ? "100%" : width,
//     height: layout === "fill" ? "100%" : height,
//     objectFit: objectFit,
//   };

//   return (
//     <div style={{ position: "relative", width, height }}>
//       <img src={src} alt={alt} loading="lazy" style={styles} {...props} />
//     </div>
//   );
// };

export default function Page() {
  var height = document.documentElement.clientHeight;
  var width = document.documentElement.clientWidth;
  var ifMobile = height < width;

  return (
    <div>
      <h1 className="text-3xl font-medium tracking-tighter">
        東京大学大学院進学塾
      </h1>
      <h1 style={{ fontSize: 15 }}>日本での大学院進学 / 就職指導</h1>

      {/* <p className="prose prose-neutral dark:prose-invert">
        专为有志考取名牌大学研究生院的学生提供最高水平的指导和支持。我们全力帮助每一位学生实现他们的目标，成功迈入理想的学术殿堂。
      </p> */}
      <div style={{ height: 50 }} />
      <Paragraph
        className="prose prose-neutral dark:prose-invert"
        style={{ color: "#ffffff", fontSize: 15 }}
      >
        专为在日留学生提供最高水平的指导和支持。专注于
        <Text mark>『大学院升学』和『就职指导』</Text>
        我们全力帮助每一位学生实现他们的目标，成功迈入理想的学术殿堂。
      </Paragraph>

      <div className="mt-5" />
      <Image
        alt="Me speaking on stage at React Summit about the future of Next.js"
        src={toudai1}
        fill
        sizes="(max-width: 768px) 213px, 33vw"
        priority
        className="rounded-lg object-cover"
      />

      <div className="mt-7 prose prose-neutral dark:prose-invert">
        <Paragraph
          className="prose prose-neutral dark:prose-invert"
          style={{ color: "#ffffff", fontSize: 15 }}
        >
          拥有丰富的教学经验的顶级的师资团队,根据每位学生的理解程度和目标制定量身定制的学习计划。通过个别辅导，彻底攻克学习中的难点和薄弱环节。
          按照你的节奏进行教学，让学习既高效又轻松。 全面的合格对策
          提供详细的志愿学校历年考题分析和面试对策，帮助你制定具体且有策略的学习计划。
          实时更新最新的考试信息和研究生院动态，提供准确的建议和指导。
        </Paragraph>
      </div>
      <div style={{ height: 30 }} />
      <h1 className="mb-8 text-xl font-medium tracking-tighter">講師</h1>
      <p></p>
      <div className="my-3 flex w-full flex-col space-x-0 space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
        <ChannelLink
          img={nin_x}
          name="@nin - 東京大学修士"
          link="https://x.com/nin_foggy?s=21"
        />
        <ChannelLink img={ryu4} name="ryudai - 東京大学修士" link="" />
      </div>
      <div className="prose prose-neutral dark:prose-invert">
        <div style={{ height: 10 }} />
        <p style={{ fontWeight: "bold" }}>周　寧</p>
        <p>
          東京大学大学院(学際情報学府)
          情報デザイン、生成AI・エピソード記憶の研究。
          現在は大手ITにて、生成AIと脳AIの融合、趣味はロボット開発とアート。
        </p>
        <div style={{ height: 10 }} />
        <p style={{ fontWeight: "bold" }}>間宮　竜大</p>
        <p>
          東京大学大学院(学際情報学府) データ分析、AIに関する研究に従事。
          現在は大手ITにて、オンライン広告のマーケティング
          趣味は海外旅行と喫茶店巡り。
        </p>
      </div>
      <div style={{ height: 50 }} />
      <Image
        alt="Me speaking on stage at React Summit about the future of Next.js"
        src={ryudaiAndNin1}
        fill
        sizes="(max-width: 768px) 213px, 33vw"
        priority
        className="rounded-lg object-cover"
      />
      <div className="grid grid-cols-2 grid-rows-2 sm:grid-rows-2 sm:grid-cols-3 gap-4 my-3">
        <Image
          alt="Me speaking on stage at React Summit about the future of Next.js"
          src={nin1}
          fill
          sizes="(max-width: 768px) 213px, 33vw"
          priority
          className="rounded-lg object-cover"
        />
        <Image
          alt="Me speaking on stage at React Summit about the future of Next.js"
          src={ryu6}
          fill
          sizes="(max-width: 768px) 213px, 33vw"
          priority
          className="rounded-lg object-cover"
        />
        <Image
          alt="Me speaking on stage at React Summit about the future of Next.js"
          src={nin2}
          fill
          sizes="(max-width: 768px) 213px, 33vw"
          priority
          className="rounded-lg object-cover"
        />
        <Image
          alt="Me speaking on stage at React Summit about the future of Next.js"
          src={ryu4}
          fill
          sizes="(max-width: 768px) 213px, 33vw"
          priority
          className="rounded-lg object-cover"
        />
      </div>
      <h1 className="mb-8 text-xl font-medium tracking-tighter">指导内容</h1>
      <div className="my-8 flex w-full flex-col space-y-4">
        <BlogLink
          name="大学院進学指導 （情報系,デザイン系)"
          slug="developer-experience"
        />
        <BlogLink name="大学院進学指導 （統計学)" />
        <BlogLink name="就活サポート" />
        <BlogLink name="面接面談指導 / 日本語指導" />
        <BlogLink name="作品集指導 / 小論文指導など" />
      </div>

      <div style={{ height: 20 }} />
      <h1 className="mb-8 text-xl font-medium tracking-tighter">合格実績</h1>
      <div className="my-8 flex w-full flex-col space-y-4">
        <BlogLinkBig name="東京大学" img={logo_td} />
        <BlogLinkBig name="早稲田大学" img={logo_waseda} />
        <BlogLinkBig name="芝浦工業大学" img={logo_shiba} />
        <BlogLinkBig name="上智大学" img={logo_sofia} />
        <BlogLinkBig name="東洋大学" img={logo_touyou} />
        <BlogLink name="..." />
      </div>
      <div style={{ height: 30 }} />
      <h1 className="mb-8 text-xl font-medium tracking-tighter">連絡方法</h1>
      <Paragraph
        className="prose prose-neutral dark:prose-invert"
        style={{ color: "#ffffff", fontSize: 15 }}
      >
        详情咨询请扫扫描以下二维码联系周老师
      </Paragraph>
      <Image
        alt="Me speaking on stage at React Summit about the future of Next.js"
        src={qr1}
        width={300}
        className="rounded-lg object-cover"
      />
      {/* <div style={{ position: "fixed", right: 20, bottom: 20 }}>
        <div
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#ff0000",
            borderRadius: 25,
          }}
        ></div>
      </div> */}
    </div>
  );
}
